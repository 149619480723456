import React, { useState } from 'react';

import { useManagedGroupUsers } from '@contactcentre-web/hooks/api/useManagedGroupUsers';

import ManagedGroupUsersHeader from './components/ManagedGroupUsersHeader/ManagedGroupUsersHeader';
import ManagedGroupUsersTable from './components/ManagedGroupUsersTable/ManagedGroupUsersTable';
import { UsersFilter } from './components/ManagedGroupUsersFilters/ManagedGroupUsersFilters';

const NUMBER_OF_ITEMS_PER_PAGE = 50;

const ManagedGroupUsers = () => {
  const [filter, setFilter] = useState(UsersFilter.ALL);
  const { data, isLoading, isError, fetchNextPage } = useManagedGroupUsers(
    NUMBER_OF_ITEMS_PER_PAGE,
    filter
  );

  const pages = data?.pages ?? [];
  const allItems = pages.flatMap(({ items }) => items);
  const {
    pageSize = 0,
    totalCount = 0,
    page = 0,
  } = pages.length > 0 ? pages[pages.length - 1] : {};

  return (
    <>
      <ManagedGroupUsersHeader />
      <ManagedGroupUsersTable
        users={allItems}
        totalCount={totalCount}
        pageSize={pageSize}
        page={page}
        isLoading={isLoading}
        isError={isError}
        loadNextPage={fetchNextPage}
        setFilterButton={setFilter}
        filter={filter}
      />
    </>
  );
};

export default ManagedGroupUsers;
