import React, { Dispatch, SetStateAction } from 'react';
import { css } from 'aphrodite/no-important';
import { useIntl } from 'react-intl';

import FilterButton from '@contactcentre-web/common/FilterButton';

import messages from './messages';
import styles from './styles';

export enum UsersFilter {
  ALL = 'all',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

interface Props {
  filter: string;
  setFilterButton: Dispatch<SetStateAction<UsersFilter>>;
}

const ManagedGroupUsersFilters = ({ filter, setFilterButton }: Props) => {
  const onClickFilter = (filterType: UsersFilter) => setFilterButton(filterType);
  const { formatMessage } = useIntl();
  return (
    <div className={css(styles.container)}>
      <FilterButton
        onClick={() => onClickFilter(UsersFilter.ALL)}
        active={filter === UsersFilter.ALL}
        testId={UsersFilter.ALL}
      >
        {formatMessage(messages.all)}
      </FilterButton>
      <FilterButton
        onClick={() => onClickFilter(UsersFilter.ENABLED)}
        active={filter === UsersFilter.ENABLED}
        testId={UsersFilter.ENABLED}
      >
        {formatMessage(messages.enabled)}
      </FilterButton>
      <FilterButton
        onClick={() => onClickFilter(UsersFilter.DISABLED)}
        active={filter === UsersFilter.DISABLED}
        testId={UsersFilter.DISABLED}
      >
        {formatMessage(messages.disabled)}
      </FilterButton>
    </div>
  );
};

export default ManagedGroupUsersFilters;
