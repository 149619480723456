import React from 'react';

import PageContainer from '@contactcentre-web/common/PageContainer/PageContainer';
import ErrorBoundary from '@contactcentre-web/common/ErrorBoundary/ErrorBoundary';
import ManagedGroupUsers from '@contactcentre-web/managed-group-users/ManagedGroupUsers';

import styles from './styles';

const ManualEntryConsolePage = () => (
  <PageContainer styleSheet={styles.container}>
    <ErrorBoundary>
      <ManagedGroupUsers />
    </ErrorBoundary>
  </PageContainer>
);

export default ManualEntryConsolePage;
