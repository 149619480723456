import { defineMessages } from 'react-intl';

export default defineMessages({
  addNewAgent: {
    id: 'UserMenu.addNewAgent',
    defaultMessage: 'Add new agent',
  },
  editUser: {
    id: 'UserMenu.editUser',
    defaultMessage: 'Edit user',
  },
  setupManagedGroup: {
    id: 'UserMenu.setupManagedGroup',
    defaultMessage: 'Set up new Managed Group user',
  },
  managePermission: {
    id: 'UserMenu.managePermission',
    defaultMessage: 'Manage permission',
  },
  editSite: {
    id: 'UserMenu.editSite',
    defaultMessage: 'Edit site',
  },
  signOut: {
    id: 'UserMenu.signOut',
    defaultMessage: 'Sign out',
  },
  discretionaryApprovals: {
    id: 'UserMenu.discretionaryApprovals',
    defaultMessage: 'Discretionary approvals',
  },
  compensationApprovals: {
    id: 'UserMenu.compensationApprovals',
    defaultMessage: 'Compensation approvals',
  },
  locale: {
    id: 'UserMenu.locale',
    defaultMessage: 'Language',
  },
  bulkUpload: {
    id: 'UserMenu.bulkUpload',
    defaultMessage: 'Bulk upload',
  },
  virtualTerminal: {
    id: 'UserMenu.virtualTerminal',
    defaultMessage: 'Virtual terminal',
  },
  virtualTerminalApprovals: {
    id: 'UserMenu.virtualTerminalApprovals',
    defaultMessage: 'Approval queue',
  },
  virtualTerminalCredit: {
    id: 'UserMenu.virtualTerminalCredit',
    defaultMessage: 'Credit',
  },
  virtualTerminalDebit: {
    id: 'UserMenu.virtualTerminalDebit',
    defaultMessage: 'Debit',
  },
  tracsOnAccount: {
    id: 'UserMenu.tracsOnAccount',
    defaultMessage: 'TraCS On Account refunds',
  },
  tracsOnAccountRefunds: {
    id: 'UserMenu.tracsOnAccountRefunds',
    defaultMessage: 'Refund terminal',
  },
  tracsOnAccountApprovals: {
    id: 'UserMenu.tracsOnAccountApprovals',
    defaultMessage: 'Approval queue',
  },
  manualEntryConsole: {
    id: 'UserMenu.manualEntryConsole',
    defaultMessage: 'MEC treasury spreadsheet',
  },
  paperTicketQueue: {
    id: 'UserMenu.paperTicketQueue',
    defaultMessage: 'Paper ticket queue',
  },
  customersRevocation: {
    id: 'UserMenu.customersRevocation',
    defaultMessage: 'Customers Revocation',
  },
  purchaseDiscountCard: {
    id: 'UserMenu.purchaseDiscountCard',
    defaultMessage: 'Purchase SNCF Discount Card',
  },
  users: {
    id: 'UserMenu.users',
    defaultMessage: 'Users',
  },
  manageUsers: {
    id: 'UserMenu.manageUsers',
    defaultMessage: 'Managed group users',
  },
});
