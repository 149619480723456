import React, { Dispatch, SetStateAction } from 'react';
import { css } from 'aphrodite/no-important';
import { Paragraph, StatusMessage } from '@trainline/depot-web';
import { useIntl } from 'react-intl';

import Loader from '@contactcentre-web/common/Loader';
import LoadMoreButton from '@contactcentre-web/common/LoadMoreButton/LoadMoreButton';
import { ManagedGroupUsersResponse } from '@contactcentre-web/hooks/api/useManagedGroupUsers';

import ManagedGroupUsersFilters, {
  UsersFilter,
} from '../ManagedGroupUsersFilters/ManagedGroupUsersFilters';

import messages from './messages';
import styles from './styles';

interface Props {
  users: ManagedGroupUsersResponse[];
  totalCount: number;
  pageSize: number;
  page: number;
  isLoading: boolean;
  isError: boolean;
  loadNextPage: () => void;
  filter: string;
  setFilterButton: Dispatch<SetStateAction<UsersFilter>>;
}

const ManagedGroupUsersTable = ({
  users,
  pageSize,
  totalCount,
  page,
  isLoading,
  isError,
  filter,
  loadNextPage,
  setFilterButton,
}: Props) => {
  const { formatMessage } = useIntl();
  const noEntries = totalCount === 0 || users.length === 0;
  return (
    <>
      {isLoading && <Loader />}
      {isError && (
        <StatusMessage status="negative">{formatMessage(messages.errorMessage)}</StatusMessage>
      )}
      {noEntries && !isLoading && (
        <StatusMessage status="info">{formatMessage(messages.errorMessage)}</StatusMessage>
      )}

      <div className={css(styles.container)}>
        <Paragraph fontWeight="bold">{formatMessage(messages.members)}</Paragraph>

        <ManagedGroupUsersFilters filter={filter} setFilterButton={setFilterButton} />

        <table className={css(styles.table)}>
          <thead>
            <tr>
              <th>{formatMessage(messages.username)}</th>
              <th>{formatMessage(messages.email)}</th>
              <th>{formatMessage(messages.employeeId)}</th>
              <th>{formatMessage(messages.site)}</th>
              <th>{formatMessage(messages.ssoProvider)}</th>
              <th>{formatMessage(messages.disabled)}</th>
              <th>{formatMessage(messages.roles)}</th>
              <th>{formatMessage(messages.lastAccess)}</th>
            </tr>
          </thead>
          <tbody>
            {users?.map(
              ({ username, email, employeeId, site, isFromSsoProvider, isDisabled, roles }) => (
                <tr key={email} data-testid="managedGroupUsersItem">
                  <td>
                    <span title={username} className={css(styles.notWrap)}>
                      {username}
                    </span>
                  </td>
                  <td>
                    <span title={email} className={css(styles.notWrap)}>
                      {email}
                    </span>
                  </td>
                  <td>{employeeId}</td>
                  <td>{site}</td>
                  <td className={css(styles.textCenter)}>
                    {formatMessage(isFromSsoProvider ? messages.yes : messages.no)}
                  </td>
                  <td className={css(styles.textCenter)}>
                    {formatMessage(isDisabled ? messages.yes : messages.no)}
                  </td>
                  <td>
                    {roles.map(({ id, name }) => (
                      <div className={css(styles.role)} key={id}>
                        {name}
                      </div>
                    ))}
                  </td>
                  <td className={css(styles.textCenter)}>Not Available</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <LoadMoreButton
        totalCount={totalCount}
        pageSize={pageSize}
        page={page}
        fetchNextPage={loadNextPage}
        isLoading={isLoading}
      />
    </>
  );
};

export default ManagedGroupUsersTable;
