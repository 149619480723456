import { defineMessages } from 'react-intl';

export default defineMessages({
  all: {
    id: 'managedGroupUsersFilters.all',
    defaultMessage: 'All',
  },
  enabled: {
    id: 'managedGroupUsersFilters.enabled',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'managedGroupUsersFilters.disabled',
    defaultMessage: 'Disabled',
  },
});
