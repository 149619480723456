import React from 'react';
import { Heading } from '@trainline/depot-web';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const ManagedGroupUsersHeader = () => (
  <Heading typeStyle="title3" as="h1">
    <FormattedMessage {...messages.title} />
  </Heading>
);

export default ManagedGroupUsersHeader;
