import { defineMessages } from 'react-intl';

export default defineMessages({
  members: {
    id: 'managedGroupUsersTable.members',
    defaultMessage: 'Members',
  },
  username: {
    id: 'managedGroupUsersTable.username',
    defaultMessage: 'Username',
  },
  email: {
    id: 'managedGroupUsersTable.email',
    defaultMessage: 'Email',
  },
  employeeId: {
    id: 'managedGroupUsersTable.employeeId',
    defaultMessage: 'Employee ID',
  },
  site: {
    id: 'managedGroupUsersTable.site',
    defaultMessage: 'Site',
  },
  ssoProvider: {
    id: 'managedGroupUsersTable.ssoProvider',
    defaultMessage: 'SSO Provider',
  },
  disabled: {
    id: 'managedGroupUsersTable.disabled',
    defaultMessage: 'Disabled',
  },
  roles: {
    id: 'managedGroupUsersTable.roles',
    defaultMessage: 'Roles',
  },
  lastAccess: {
    id: 'managedGroupUsersTable.lastAccess',
    defaultMessage: 'Last Access',
  },
  yes: {
    id: 'managedGroupUsersTable.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'managedGroupUsersTable.no',
    defaultMessage: 'No',
  },
  notAvailable: {
    id: 'managedGroupUsersTable.notAvailable',
    defaultMessage: 'Not Available',
  },
  errorMessage: {
    id: 'managedGroupUsersTable.errorMessage',
    defaultMessage: 'Failed to load data. Please try again.',
  },
  noUsers: {
    id: 'managedGroupUsersTable.noUsers',
    defaultMessage: 'There are no users.',
  },
});
