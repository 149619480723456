import { useInfiniteQuery } from 'react-query';

import request from '@contactcentre-web/utils/request';

import { PaginationResponse } from './sharedTypes';

const MANAGED_GROUP_USERS_KEY = 'ManagedGroupUsersList';

interface Role {
  managedGroupId: string;
  id: string;
  name: string;
}

export interface ManagedGroupUsersResponse {
  username: string;
  email: string;
  employeeId: string;
  site: string;
  isFromSsoProvider: boolean;
  isDisabled: boolean;
  roles: Role[];
}

interface ManagedGroupUsersRequest {
  pageSize: number;
  filter: string;
}

const fetchItems = ({
  pageParam = 1,
  queryKey,
}: {
  pageParam?: number;
  queryKey: (string | ManagedGroupUsersRequest)[];
}) => {
  // first element is the query key string, we can ignore/skip it
  const [, queryParams] = queryKey;
  const { pageSize, filter } = queryParams as ManagedGroupUsersRequest;

  const query = [`page=${pageParam}`, `perPage=${pageSize}`, `filter=${filter}`].join('&');

  return request<PaginationResponse<ManagedGroupUsersResponse>>(
    `/authapi/users/getUsersByManagedGroup?${query}`
  );
};

export const useManagedGroupUsers = (pageSize: number, filter: string) =>
  useInfiniteQuery([MANAGED_GROUP_USERS_KEY, { pageSize, filter }], fetchItems, {
    getNextPageParam: (lastPage) => (lastPage.page || 0) + 1,
  });
